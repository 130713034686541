import CryptoJS from "crypto-js";
import React, { useEffect, useState } from "react";

import { motion } from "framer-motion";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { ReactComponent as SearchIcon } from "../../assets/icon/ic_search.svg";

import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as FAktaIcon } from "../../assets/icon/ic_fakta.svg";
import { ReactComponent as HoaxIcon } from "../../assets/icon/ic_hoax.svg";
import { ReactComponent as SortIcon } from "../../assets/icon/ic_sort.svg";
import Button from "../../components/common/Button";
import Input from "../../components/common/Input";
import LoadingLink from "../../components/common/LoadingLink";
import ConditionalRender from "../../components/ui/ConditionalRender";
import { apiClient } from "../../utils/api/apiClient";
import BrandSlider from "../homepages/BrandSlider";

function SearchingPAges() {
  const isWebSetting = localStorage.getItem("isWebSetting");
  const parseWebSetting = JSON.parse(isWebSetting);
  const [searchData, setSearchData] = useState([]);
  const [popularTopicData, setPopularTopicData] = useState([]);
  const [searchArticles, setsearchArticles] = useState([]);

  const [searchArticlesLoading, setsearchArticlesLoading] = useState(true);

  const [search, setSearch] = useState("");
  const [sort, setSort] = useState(true);
  const [showOverlay, setShowOverlay] = useState(false);

  const [itemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1000);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalData, setTotalData] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;

  useEffect(() => {
    getPopularTopic();
  }, []);

  useEffect(() => {
    setSearch(data)
    fetchDatasearchArticles(data)
    setShowOverlay(false);
  }, [data]);

  useEffect(() => {
    fetchDatasearchArticles(search)
  }, [sort, currentPage]);

  useEffect(() => {
    if (search?.length > 3) {
      getSearch(search);
    } else {
      setSearchData([]);
      setShowOverlay(false);
    }
  }, [search]);

  const getPopularTopic = async (keyword) => {
    try {
      const response = await apiClient({
        baseurlCostum: true,
        baseurl: "https://backend.cekhoax.id/api/search/popular_topics",
        method: "GET",
        customHeaders: {
          "X-Authorization":
            "bK6q6pS5zeNQvkxXPniVoRzGYp5Z4VwUrOCpcA8G8ffNYouYQ04CujQ8KLvhnQRD",
        },
      });
      if (response?.statusCode === 200) {
        setPopularTopicData(response.result.data);
      } else {
        setPopularTopicData([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getSearch = async (keyword) => {
    try {
      const params = new URLSearchParams();
      params.append("query", keyword);

      const response = await apiClient({
        baseurlCostum: true,
        baseurl: "https://backend.cekhoax.id/api/search/suggestion",
        body: params,
        method: "POST",
        customHeaders: {
          "X-Authorization":
            "bK6q6pS5zeNQvkxXPniVoRzGYp5Z4VwUrOCpcA8G8ffNYouYQ04CujQ8KLvhnQRD",
        },
      });
      if (response?.statusCode === 200) {
        setSearchData(response.result.data);
        if (response.result.data?.length <= 0) {
          setShowOverlay(false);
        } else {
          setShowOverlay(true);
        }
      } else {
        setSearchData([]);
        setShowOverlay(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const getSetSearch = async (slug) => {
    try {
      const params = new URLSearchParams();
      params.append("slug", slug);

      const response = await apiClient({
        baseurlCostum: true,
        baseurl: "https://backend.cekhoax.id/api/article/detail_article",
        body: params,
        method: "POST",
        customHeaders: {
          "X-Authorization":
            "bK6q6pS5zeNQvkxXPniVoRzGYp5Z4VwUrOCpcA8G8ffNYouYQ04CujQ8KLvhnQRD",
        },
      });
      if (response?.statusCode === 200) {
        fetchsetDatabase(response.result.data);
        // setShowOverlay(true);
      } else {
        // setShowOverlay(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchsetDatabase = async (item) => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const sha256Hash = CryptoJS.MD5(year + "-" + month + "-" + day).toString();

    const params = new URLSearchParams();
    params.append("api_key", sha256Hash);
    params.append("slug", item?.slug);
    params.append("source", item?.source);
    params.append("source_url", item?.source_url);
    params.append("image_url", item?.image_url);
    params.append("title", item?.title);
    params.append("content_raw", item?.content_raw);
    params.append("fact", item?.fact);
    params.append("summary", item?.summary);
    params.append("tags", item?.tags);
    params.append("reading_time_minutes", item?.reading_time_minutes);
    params.append("view_count", item?.view_count);
    params.append("published_date", item?.published_date);
    params.append("created_at", item?.created_at);
    params.append("updated_at", item?.updated_at);
    params.append("status_article", item?.status_article);

    try {
      const response = await apiClient({
        baseurl: "set_articles",
        method: "POST",
        apiKey: sha256Hash,
        body: params,
      });
      if (response?.statusCode === 200) {
        console.log(response.result.data);
        navigate("/detail/" + item?.slug)
      } else {
        navigate("/detail/" + item?.slug)
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDatasearchArticles = async (search) => {
    setsearchArticlesLoading(true);
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const sha256Hash = CryptoJS.MD5(year + "-" + month + "-" + day).toString();

    const params = new URLSearchParams();
    params.append("api_key", sha256Hash);
    params.append("search_title", search ===null ? '' : search);
    params.append("filter", sort ? 'terbaru' : 'terlama');
    params.append("page", currentPage);
    params.append("limit", itemsPerPage);

    try {
      const response = await apiClient({
        baseurl: "search_articles",
        method: "POST",
        apiKey: sha256Hash,
        body: params,
      });
      setsearchArticlesLoading(false);
      if (response?.statusCode === 200) {
        setTotalPages(response?.result?.total_pages);
        setTotalData(response?.result?.total_data);
        setsearchArticles(response.result.data);

      } else {
        setsearchArticles([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleNextPage = () => {
    setCurrentPage((halamanSebelumnya) => halamanSebelumnya + 1);

    console.log(totalPages);
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage((halamanSebelumnya) => halamanSebelumnya - 1);
    }
  };

  return (
    <div className="flex flex-col gap-3 flex-1 lg:container lg:mx-auto xl:max-w-screen-xl" onClick={() => setShowOverlay(false)}>
      <div className="flex sm:flex-row flex-col-reverse p-3 gap-3 ">
        <div className="flex flex-1 flex-col gap-3 justify-center ">
          <span className="md:text-4xl text-3xl font-bold">
            Menyaring kabar palsu <br />
            ungkap fakta sejati <br />
            Lawan <b className="text-[#FF2109]">HOAX</b>
          </span>
          <span className="text-sm opacity-70">
            Sumber terpercaya untuk memeriksa dan memverifikasi kebenaran
            berita. Kami hadir untuk membantu Anda memilah-milah informasi yang
            benar dari yang salah, serta mengidentifikasi dan mengekspos berita
            palsu yang dapat menyesatkan.
          </span>

          <OverlayTrigger
            trigger="click"
            placement="bottom-start"
            show={showOverlay}
            onHide={() => setShowOverlay(false)}
            overlay={
              <Popover id="popover-basic">
                <Popover.Header as="h3">Pencarian Populer</Popover.Header>
                <Popover.Body className="p-2">
                  {
                    <div className="flex flex-col w-full">
                      {searchData?.map((item, index) => (
                        <button
                          onClick={() => {
                            getSetSearch(item.slug)

                          }}
                          key={index}
                          className="rounded-lg p-1 flex w-full justify-start hover:bg-[#21212118]  hover:p-2 items-center group space-x-3 transform  ease-out"
                        >
                          <SearchIcon className="h-6 w-6" fill="#212121" />
                          <div className="flex flex-col items-start flex-1">
                            <span className=" group-hover:text-[#1b1b41] text-sm group-hover:font-medium group-hover:transform  ease-out text-left">
                              {item.title}
                            </span>
                          </div>
                        </button>
                      ))}
                    </div>
                  }
                </Popover.Body>
              </Popover>
            }
          >
            <div className="flex flex-row gap-2 items-center bg-lightColor ustify-between shadow-inner p-1.5 rounded-full w-full ">
              <Input
                id={1}
                value={search}
                onChange={(value) => {
                  const inputValue = value;
                  setSearch(inputValue);
                }}
                type={"search"}
                color={"#666666"}
                icon={<SearchIcon />}
                className="h-9 border-transparent bg-transparent "
                placeholder={`Cek Kebenaran`}
              />

              <button onClick={() => {
                setShowOverlay(false);
                setCurrentPage(0);
                fetchDatasearchArticles(search);
              }}
                className="bg-[#5F4BDB] flex items-center justify-center h-9 aspect-square rounded-full z-10">
                <SearchIcon className="h-5 w-5" fill="#ffffff" />
              </button>
            </div>
          </OverlayTrigger>
          <div className="flex gap-1 flex-wrap items-center">
            <span className="text-xs opacity-50">Populer :</span>
            {popularTopicData?.map((item, index) => (
              <button
                onClick={() => {
                  setSearch("");
                }}
                key={index}
                className="inline-flex flex-row  px-3 py-1 rounded-full text-sm border-1 group hover:bg-[#5F4BDB] border-[#5F4BDB] gap-2"
              >
                <span className="text-xs text-[#5F4BDB] group-hover:text-darkColor">
                  {item}
                </span>
              </button>
            ))}
          </div>
        </div>
        <div className="md:flex hidden flex-col items-center basis-5/12 xl:basis-4/12  relative">
          <div className="bg-[#B2C3FF] rounded-full w-full aspect-square "></div>
          <motion.div
            animate={{
              x: [0, -5, 0],
              transition: {
                duration: 10,
                ease: "linear",
                repeat: Infinity,
                repeatDelay: 0,
                delay: 0,
              },
            }}
            className="absolute -bottom-12 -left-12 -right-12 -top-12"
          >
            <img
              alt="ornamen_1"
              src={require("../../assets/image/ornament_1.png")}
              className="w-full h-full object-contain  pointer-events-none"
            />
          </motion.div>
        </div>
      </div>


      <div className="flex flex-col gap-3 flex-1 lg:container lg:mx-auto xl:max-w-screen-xl p-3">
        <div className="flex flex-row justify-between gap-2">
          <span className="text-xl font-bold">{totalData} Pencarian Tersedia
          </span>
          <Button
            initialValue={sort ? 'Terbaru' : 'Terlama'}
            type="transparent"
            fill={'#5F4BDB'}
            iconLeft={<SortIcon className={`h-5 w-5 ${sort ? 'rotate-180' : ''}`} />}
            className="inline-flex px-4 py-2 rounded-full text-sm border-1 border-[#5F4BDB] color-[#5F4BDB] gap-2"
            onClick={() => {
              setSort(!sort)
            }}
          />
        </div>
        <div className="flex flex-1 flex-col bg-lightColor p-3 gap-2 rounded-md shadow-inner">
          <ConditionalRender
            productsData={searchArticles}
            isLoading={searchArticlesLoading}
            model={"emptyData"}
          >
            {searchArticles?.slice(0, 20).map((item, index) => {
              const updatedDate = new Date(item.updated_at);
              const month = updatedDate.toLocaleString("id-ID", {
                month: "short",
              });
              const day = String(updatedDate.getDate())?.padStart(2, "0");

              return (
                <LoadingLink
                  key={index}
                  to={"/detail/" + item?.slug}
                  className=" flex flex-1 flex-col text-lightColor bg-lightColor hover:bg-[#f3f3f3] no-underline "
                >
                  <div className="flex flex-row  p-2 gap-2 rounded-md">
                    <div className="flex flex-col">
                      <img
                        src={item.image_url}
                        alt={item.title}
                        className="h-full object-cover aspect-[2/1] w-20 rounded-md bg-red-200"
                        effect="blur"
                      />
                    </div>
                    <div className="flex flex-1 flex-col justify-center">
                      <span className="text-md line-clamp-2">{item.title}</span>
                      <span className="text-xs line-clamp-1">{item.updated_at}</span>
                    </div>
                    <div className="flex flex-col justify-center">
                      <div
                        className={`${item.status_article === "Fakta" ? "bg-[#2BC155]" : "bg-[#FF2109]"} flex flex-col w-8 aspect-square rounded-full mt-2 p-1`}
                      >
                        {item.status_article === "Fakta" ? (
                          <FAktaIcon
                            className="w-full h-full"
                            fill={"#2BC155"}
                          />
                        ) : (
                          <HoaxIcon
                            className="w-full h-full"
                            fill={"#2BC155"}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </LoadingLink>
              );
            })}
          </ConditionalRender>
          {searchArticles?.length !== 0 && (
            <div className=" gap-2 flex right-3 justify-center">
              <Button
                disabled={currentPage <= 0}
                initialValue="Sebelumnya"
                type="fill"
                className={`border-[#5f4bdb] border-2 text-[#5f4bdb] dark:border-[#ffffff] h-[40px] dark:text-darkColor :inline-flex rounded-lg ${currentPage <= 0 ? "cursor-not-allowed" : "cursor-pointer"}`}
                onClick={() => {
                  handlePreviousPage();
                }}
              />
              <Button
                disabled={
                  searchArticles?.length === 0 || currentPage >= totalPages
                }
                initialValue="Selanjutnya"
                type="fill"
                className={`border-[#5f4bdb] border-2 text-[#5f4bdb] dark:border-[#ffffff] h-[40px] dark:text-darkColor inline-flex rounded-lg ${searchArticles?.length <= 0 ? "cursor-not-allowed" : "cursor-pointer"}`}
                onClick={() => handleNextPage()}
              />
            </div>
          )}
        </div>
      </div>
      <BrandSlider />
    </div>
  );
}

export default SearchingPAges;
